const copySequence = {
	id: 'copySequence',
	selectionType: 'single',
	label: 'actions.locationsequenceheader.copySequence',
	functionality: 'INSERT_LOCATIONSEQUENCEHEADER',
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		const url = '/locationsequenceheader/copySequence';
		this.$puiRequests.postRequest(
			url,
			null,
			(response) => {
				this.$puiNotify.success(this.$t('pui9.save.success'));
				this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
			},
			(error) => {
				this.$puiNotify.error(this.$t('pui9.save.error'));
				this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
			},
			null,
			{ locationSequenceId: registries[0].id }
		);
	}
};

export default {
	gridactions: [copySequence],
	formactions: []
};
